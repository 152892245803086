import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Error from './components/Error'
export default function Forzerofor ( ){
    return(
      <>
      <Navbar />
      <Error/>
      <Footer />
      </>
    )
  }