const Error =()=>{
    return(
        <div className="FakeContent">
            <div  className="fakeconstruction">
            <img className="construction-image" src="./utilities/MisoraIdol.webp" alt="Hina-Misora"/>
            <b className="construction">PÁGINA EN CONSTRUCCIÓN...</b>
            </div>
        </div>
    )
};
export default Error;