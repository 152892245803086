export default function Void(){
    return(
        <div className="FakeContent">
            <div>
            <div className="spinner">

            </div>
            <b>LOADING....</b>
            </div>

        </div>
    )
}