const sounds = [
    'a la chingada dice la suegra',
    'aber',
    'ah no aaaaaaaa',
    'alt+f4',
    'asi bonito',
    'bajale un poco a esas temperaturas',
    'baka',
    'bueno chicos amo a jugar',
    'callense obejas',
    'calzon negro',
    'cama horny',
    'cama sexy',
    'chotto matte ne',
    'cinturon shipla',
    'cinturon',
    'como que khomo',
    'cuidate de los creepers',
    'ducha grupal',
    'el chat se fue',
    'entendi la indirecta',
    'epaaa',
    'espera me voy a desnudar',
    'esperate wey',
    'ey woof',
    'fuera de contexto',
    'funado',
    'hace un chingo de calor',
    'hazukashii',
    'hijo de la chinga',
    'hina furra',
    'hina signo de interrogacion dos',
    'hina signo de interrogacion',
    'ja culo',
    'khomo',
    'la verdadera voz',
    'luna shipla',
    'me moje',
    'mi mama ya es abuela',
    'mira esta hermosura',
    'no mames',
    'no no no',
    'nononononononononono',
    'oppai',
    'quack quack',
    'que mierda es esto',
    'que quieren ver',
    'que tengas un bonito dia mua',
    'son un par de amores',
    'toque agua',
    'una frase bonita',
    'ustedes son grandotes',
    'vale',
    'vamos a hacer yuculito',
];

export default sounds;